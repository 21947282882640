<template>
  <b-modal
    :visible="visible"
    hide-footer
    hide-header
    centered
    @change="$emit('change', false)"
  >
    <div class="my-4">
      <span
        class="b-avatar shadow mb-2 badge-primary rounded-circle bg-light-primary d-block mx-auto"
        style="width: 50px; height: 50px;"
      >
        <feather-icon size="28" :icon="isUpload ? 'UploadIcon' : 'DownloadIcon'" class="mt-75" />
      </span>
      <h3 class="text-center font-weight-normal">
        {{ isUpload ? $t('upload.message') : $t('download.message') }}
      </h3>
      <h5 class="text-center text-primary">{{ $t('download.alert')}}</h5>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'LoadingModal',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    isUpload: Boolean,
  },
};
</script>
