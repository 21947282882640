<template>
  <div v-if="files && files.length > 0" class="d-flex flex-wrap">
    <file-preview
      v-for="(f, index) in files"
      :key="index"
      class="file-upload mr-1"
      :src="f"
      :mime-type="file[index].type || type"
      :file-name="file[index].name"
      :cancelable="cancelable"
      :disabled="disabled"
      :index="index"
      style="max-height: 200px; max-width: 200px"
      @cancel="
        handleRemove
      "
    />
  </div>
  <file-preview
    v-else-if="file && (!files || files.length === 0)"
    class="file-upload"
    :src="filePath"
    :mime-type="file.type || type"
    :file-name="file.name"
    :cancelable="cancelable"
    :disabled="disabled"
    @cancel="
      $emit('input', null);
      $emit('change', null);
    "
  />
  <b-form-file
    v-else
    ref="formFile"
    :value="file"
    class="file-upload file-upload--upload w-100"
    :state="state"
    :accept="filesToAccept"
    :placeholder="placeholder"
    :drop-placeholder="dropPlaceholder"
    :disabled="disabled"
    :multiple="multiple"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import FilePreview from './FilePreview.vue';

export default {
  name: 'FileUpload',
  components: { BFormFile, FilePreview },
  model: {
    prop: 'file',
    event: 'input',
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    file: {
      type: [File, Array],
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    dropPlaceholder: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      files: null,
    };
  },
  computed: {
    filesToAccept() {
      if (this.type === 'image') {
        return 'image/*';
      }
      if (this.type === 'video') {
        return 'video/*';
      }
      if (this.type === 'document') {
        return (
          '.pdf,.doc,.docx,.xlsx,.odt,.odf,application/msword'
          + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
      if (this.type === 'spreadsheet') {
        return (
          '.pdf,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }
      if (this.type === 'audio') {
        return '.mp3';
      }
      if (this.type === 'csv') {
        return '.csv';
      }
      if (this.type === 'imageOrPdf') {
        return '.pdf,image/*';
      }
      return null;
    },
    filePath() {
      if (Array.isArray(this.file)) {
       return this.files = this.file.map((item) => URL.createObjectURL(item))
      }
      return URL.createObjectURL(this.file);
    },
  },
  methods: {
    handleRemove(index) {
      this.files.splice(index, 1);
      this.file.splice(index, 1);
      if (this.files.length === 0) {
        this.file = null;
        this.$emit('input', null)
        this.$emit('change', null)
      }
    },
    open() {
      if (!this.file) {
        this.$refs.formFile.$refs.input.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.file-upload {
  &--upload::v-deep {
    width: auto;
    .custom-file-label {
      cursor: pointer;
      text-align: center;
      height: auto !important;
      @include button-outline-variant($primary);
      &::after {
        display: none;
      }
    }
  }
}
</style>
