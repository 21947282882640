<template>
  <!-- Create Forum -->
  <b-modal
    id="modal-create-forums"
    :title="$t('Forum')"
    cancel-variant="outline-secondary"
    :ok-title="$t('form.actions.save')"
    :cancel-title="$t('form-create-item.cancel')"
    centered
    size="lg"
    @ok="handleCreateForum"
  >
    <b-form>
      <b-form-group>
        <label for="name"> {{ $t("form-create-item.title") }}:</label>
        <b-form-input
          id="name"
          v-model="form.name"
          :placeholder="$t('form.placeholder')"
          type="text"
        />
      </b-form-group>
      <b-form-group>
        <label
          class="mt-2"
          for="description"
        >{{ $t("form-create-item.description") }}:</label>
        <quill-editor v-model="form.description" />
      </b-form-group>

      <b-col cols="12" md="12" class="d-flex">
        <b-form-group label="Banner" label-for="banner">
          <file-upload
            v-model="imageForum"
            type="image"
            cancelable
            :placeholder="$t('form-create-item.image')"
            :drop-placeholder="$t('form-create-item.drop-placeholder')"
            @cancel="imageSrc = null"
          />
        </b-form-group>
        <!-- Send notification -->
        <b-form-group v-if="canSendNotification" class="d-flex pl-2" style="display: flex !important; margin-top:8px;">
          <b-form-checkbox v-model="sendNotification" />
          <p>
            {{ $t("posts.post-creation.action.email-notification") }} (Staff)
          </p>
        </b-form-group>
      </b-col>
    </b-form>
  </b-modal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import ForumPlaceholder from '@/assets/images/placeholders/light/forums.svg';
import FileUpload from '@core/components/files/FileUpload.vue';
import LoadingModal from '@core/components/modal/LoadingModal.vue';


export default {
  name: 'ForumCreateEditModal',
  components: {
    quillEditor,
    FileUpload,
    LoadingModal,
  },
  data() {
    return {
      form: {},
      imageForum: null,
      imageSrc: null,
      sendNotification: false
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return ForumPlaceholder;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    canSendNotification() {
      return this.isStaff;
    },
  },
  watch: {

  },
  // Fetch data when component is created!
  async created() {

  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleCreateForum() {
      if (!this.form.name) {
        return;
      }
      try {
        const requestConfig = { name: {}, description: {} };
        const locale = this.$store.state.locale.currentLocale;
        requestConfig.name[locale] = this.form.name;
        requestConfig.description[locale] = this.form.description;
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'forums',
            storedKey: 'list',
            requestConfig,
            sendEmailNotification: this.sendNotification,
          },
          file: this.imageForum,
        });
        this.form = {};
        this.imageForum = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>

<style>

</style>
